<template>
  <section class="page">
    <div class="page-title-block">
      <div class="d-flex align-center">
        <h3>Отчеты</h3>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ReportsMain',
};
</script>
